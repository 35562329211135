<template>
    <div>
      <div>
        <div class="form-group">
          <label for="exampleFormControlSelect1"
            ><h5 style="width: 200px">
              <i class="bi bi-person-plus"></i> 학생(아동)별 보고서
            </h5></label
          >

          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="학생(아동) 이름" v-model="studentname" id="studentname" aria-label="학생(아동) 이름" aria-describedby="button-addon2" v-on:keyup.enter="search()">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" type="button" id="button-addon2"   @click="search()">검색</button>
            </div>
          </div>         
        </div>
      </div>
  
      <div class="list-group" style="background: red;">
        <button type="button" v-for="item in searchlist" v-bind:key="item" class="list-group-item list-group-item-action" @click="personReport(item)">
          <div  v-if="item.photo !== null" class="image-profilecontainer form-group  mb-2 ">
            <img :src="photourl(item.photo)" alt="..." class="img-thumbnail" :style="profileimageStyle">
          </div>
          <div  v-else-if="item.photo2 !== null" class="image-profilecontainer form-group  mb-2 ">
            <img :src="photourl(item.photo2)" alt="..." class="img-thumbnail" :style="profileimageStyle">
          </div>
          <div  v-else class="form-group mx-sm-3 mb-2" style=" text-align: left;">
            <img src="../assets/noimage.png" style="width: 15%;">
          </div>  
          
          {{item.gradename}} - {{item.name}}({{item.sex}})  - {{item.teachername}} 선생님</button>
        <button type="button" v-if="searchlist.length == 0" class="list-group-item list-group-item-action">검색된 학생(아동)이 없습니다.  (반 학생(아동)만 조회가 가능합니다)</button>
      </div>

   <!--  재적 인원 -->
     <div style="height: 20px;"></div>
  <!--  출석 인원 -->
  <div class="card">
    <h5 class="card-header">양육 보고서</h5>
    <div class="card-body">

      <h5 class="card-title"></h5>
      <p class="card-text">

        <table class="table table-bordered" >
            <thead>
              <tr style="background: #007bff40;text-align: center;">
                <th scope="col" width=20%>출석일</th>
                <th scope="col" width=40%>보고서</th>
                <th scope="col" width=40%>피드백</th>
              </tr>
            </thead>
            <tbody>
              <tr  v-for="item in items" v-bind:key="item">
                <td style="text-align: center;">{{item.atdate}}<p v-if="item.attendyn =='Y'">(출석)</p><p v-else style="color: red;">(결석)</p></td>
                <td>{{item.pray}}</td>
                <td>{{item.note}}</td>
              </tr>
            </tbody>
          </table>
            <!-- <thead>
              <tr style="text-align: center;">
                <th scope="col">

                    <div  v-if="item.photo !== null" class="image-profilecontainer form-group  mb-2 ">
                        <img :src="photourl(item.photo)" alt="..." class="img-thumbnail" :style="profileimageStyle">
                      </div>
                      <div  v-else class="form-group mx-sm-3 mb-2" style=" text-align: left;">
                        <img src="../assets/noimage.png" style="width: 15%;">
                      </div>   

                </th>
                <th scope="col">{{item.gradename}}</th>
                <th scope="col">{{item.name}}</th>
              </tr>
            </thead> -->
            <!-- <tbody>
              <tr style="text-align: left;">
                <td colspan="3" v-html="getContent(item.pray)"></td>                
              </tr>
              <tr style="text-align: left;" v-show="item.note !== null">
                <td colspan="4" style="color: blue;">{{item.note}} &nbsp;&nbsp;
                  <span class="image-container" v-if="item.readyn == '1'"><img src="../assets/read1.png" alt="..."  :style="imageStyle" @click="iread(item,'0')"></span>
                  <span class="image-container" v-else><img src="../assets/read0.png"  alt="..."  :style="imageStyle" @click="iread(item,'1')"></span>
                  </td>                                
              </tr>                   
            </tbody> -->

      </p>
    </div>
  </div>
  <p></p>
<div>
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center">
      <li class="page-item disabled">
        <a class="page-link"  v-if="page > 1" @click="previousPage" aria-label="Previous" ><span aria-hidden="true">&laquo;</span></a>
      </li>
      <li class="page-item" v-for="pageNumber in pageNumbers" :key="pageNumber" @click="changePage(pageNumber)" :class="{ active: pageNumber === page }"><a class="page-link" >{{ pageNumber }}</a></li>
      <li class="page-item">
        <a class="page-link" v-if="page < totalPages" @click="nextPage" aria-label="Next" ><span aria-hidden="true">&raquo;</span></a>
      </li>
    </ul>
  </nav>
</div>

    </div>
</template>      

<script>
    import manageService from "../services/manageService";   
    export default {
      name: "list",
      component: {},
      data() {
        return {       
            id: this.$store.state.id,
            grade: this.$store.state.grade,
            phone: this.$store.state.phone,
            name: this.$store.state.name,
            teacherid: this.$store.state.teacherid,
            role: this.$store.state.role,
            token: this.$store.state.token,  
            classid: this.$store.state.classid,  
            studentname: "",
            searchlist: {},
            enrollList: {},
            items: [],
            page: 1,
            limit: 10,
            totalItems: 0,        
            selectitem: {},    
            imageWidth: 30,      
            profileimgWidth: 100,               
        };
      },
      created() {

      },  
      computed: {
        //...mapState(["isLogin", "userInfo"]),
        photourl() {
          return (url) => {
            return "./upload/photo/"+url;
            };
          },  
          imageStyle() {
              return {
                width: `${this.imageWidth}px`,
                border: 'none',
              }
            },   
            profileimageStyle() {
              return {
                width: `${this.profileimgWidth}px`,
                border: 'none',
              }
            },             
            totalPages() {
              return Math.ceil(this.totalItems / this.limit);
            },
            pageNumbers() {
              const numbers = [];
              for (let i = 1; i <= this.totalPages; i++) {
                numbers.push(i);
              }
              return numbers;
            },

      },
      methods: {
        search(){
        if(this.studentname.length < 2){
            this.$toast.show("두 글자 이상 입력해 주세요.", {
                  type: "warning",
                  position: "top-right",
                });
            return;
        }
            
       var data = {
            classid: this.classid,
            studentname : this.studentname,
            grade: this.grade,
            role: this.role,
          }

          // 학생명 조회
          manageService
            .searchstudent(data)
            .then((response) => {
              this.searchlist = response.data;
            })
            .catch((e) => {
            console.log(e);
            });  



        },
        personReport(iitem){          
          if(iitem == null ) iitem = this.selectitem;
          this.selectitem = iitem;
         var data = {
            classid: this.classid,
            studentid : iitem.studentid,
            no : iitem.no,
            page: this.page,
            limit : this.limit,
          }

          //보고서 리스트
          manageService
            .personreports(data)
            .then((response) => {
             
              //this.enrollList = response.data;
             this.totalItems = response.data[0][0];
             this.items = response.data.slice(1,30);
            })
            .catch((e) => {
            console.log(e);
            });  

        },

        iread(iitem, param){
          var data = {
            classid: this.classid,
            no : iitem.no,
            readyn : param,
          }
          manageService
            .setReadCheck(data)
            .then((response) => {
              console.log("response", response);
              this.readyn = true;
              this.getTotalAttend();
            })
            .catch((e) => {
            console.log(e);
            });          
        },
        getContent(text){
            if(text){
                return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
            }
        },           
        getNum(val) {
            if (isNaN(val)) {
              return 0;
            }
            return val;
        },
        getDayList(){      //날짜 조회
            manageService
            .getDaysList()
            .then((response) => {

            this.dayLists = response.data.slice(0, this.page);        
            })
            .catch((e) => {
            console.log(e);
            });
            },

        getTotalAttend(){     //전체 출결 통계 

          if(this.holiday === "00") return;

            const data = {
                attenddate: this.holiday,
                grade: this.grade,
                classid: this.classid,
                role: this.role,
                };
  
            manageService
            .getweeklyreport(data)
            .then((response) => {

            this.enrollList = response.data;   
                  
            })
            .catch((e) => {
            console.log(e);
            });
        },
        changePage(page) {
          this.page = page;
          this.personReport();
        },
        previousPage() {
          if (this.page > 1) {
            this.page--;
            this.personReport();
          }
        },
        nextPage() {
          if (this.page < this.totalPages) {
            this.page++;
            this.personReport();
          }
        },       
    },   
      mounted() {
        //this.getTotalAttend(); //전체 출석
        this. getDayList(); //날짜 조회
      },
    };
    </script>
    
    <style scoped>
    .list {
      text-align: left;
      max-width: 750px;
      margin: auto;
    }
    .done {
      background: oldlace;
    }
    .card-body {
      padding: 0.5rem;
    }
    .table td, .table th {
        padding: 0.2rem;
        vertical-align: middle;
    }
    @media (max-width: 600px) {
      .image-container img {
        width: 25px;
      }
      .image-profilecontainer img {
        width: 25px;
      }      
    }
    </style>
    